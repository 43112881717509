@import "../../variables";

.aff-reward-container {
  .center-inner-container {
    position: relative;

    .aff-reward-inner-container {
      padding: 20px;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      
      font-family: "Zen Kaku Gothic Antique";

      div {
        color: $theme-color-4;
        font-size: $font-size-xl;
      }
      .trans-history {
        color: $theme-color-4;
        font-size: $font-size-md;
        .trans-history-item {
          color: $theme-color-4;
          font-size: $font-size-md;
          flex-direction: row;
          display: flex;
          align-items: center;
          .title {
            color: $theme-color-3;;
            font-size: $font-size-md;
            text-align: left;
          }
          .description {
            color: $theme-color-7;
            font-size: $font-size-md;
            text-align: left;
          }
          .status {
            font-size: $font-size-md;
            text-align: center;
          }
        }
      }

      p {
        a {
          color: $theme-color-3;
        }

        color: $theme-color-4;
        font-size: $font-size-md;
      }

      h2.ant-typography {
        @media screen and (max-width: $md) {
          font-size: 3em;
        }
        @media screen and (max-width: $sm) {
          font-size: 2em;
        }
        font-size: 4em;
        font-family: "Zen Kaku Gothic Antique";
        color: $color-white-1;
        text-align: center;
      }
    }

  }
}