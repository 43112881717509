$fontPath: "./fonts";
@font-face {
  font-family: "Magic School Two";
  src: url("./fonts/MagicSchoolTwo.woff");
}
@font-face {
  font-family: "Magic School One";
  src: url("./fonts/magic-school.one.woff");
}

@font-face {
  font-family: "Aisha Script";
  src: url("./fonts/Aishascript-yxMV.woff");
}

@font-face {
  font-family: "Truetypewriter Polyglott";
  src: url("./fonts/TruetypewriterPolyglott-mELa.woff");
}
@font-face {
  font-family: "Zen Kaku Gothic Antique";
  src: url("./fonts/ZenKakuGothicAntique-Regular.ttf");
}
