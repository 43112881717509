@import "variables";
@import "./components/CustomComponent/CustomComponent";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.ant-form-item-control-input input::placeholder{
  color: $theme-color-7;
}
.ant-modal {
  @media screen and (orientation: landscape) {
    max-height: 100vh;
  }
}

.nft-card-modal .ant-modal-content {
  font-family: Cinzel, sans-serif;

  h3.ant-typography {
    font-size: $font-size-md;
    color: $theme-color-1;
    text-align: center;
  }

  @media screen and (orientation: landscape) {
    .ant-image-img {
      max-height: 80vh;
      width: auto;
      max-width: 100%;
    }
  }

  .ant-image {
    border-radius: 20px;
    border: solid 4px $theme-color-2;
    overflow: hidden;
  }

  .ant-card {
    .ant-card-head-title {
      text-align: center;
      font-weight: bold;
      font-size: $font-size-md;
      color: $theme-color-2;
    }

    .ant-card-grid {
      padding: 2px 1px;
      border-radius: 10px;
      overflow: hidden;

      > div {
        border: 1px solid $color-blue-1;
        border-radius: 10px;
        overflow: hidden;
        cursor: default;
      }

      .card-grid-item-title {
        color: $theme-color-5;
      }

      .card-grid-item-value {
        font-family: $font-4;
        color: $theme-color-2;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .show-sm-only {
    display: block;
  }
  .show-md-only {
    display: none;
  }
}

@media screen and (min-width: $md) {
  .show-sm-only {
    display: none;
  }
  .show-md-only {
    display: block;
  }
}

.logo-home {
   display: inline-block;
   position: fixed;
   cursor: pointer;
   left: 30px;
   top: 30px;
   width: 180px;
}