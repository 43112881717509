@import "../../variables";

.NFTImage {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  gap: 10px;
}

.ant-scrolling-effect .nft-container {
  animation: fadeOut .5s;
  opacity: 0;
}

body:not(.ant-scrolling-effect) .nft-container {
  animation: fadeIn .5s;
  opacity: 1;
}

.nft-container {
  // font-family: Cinzel, sans-serif;

  .center-inner-container {
    padding-top: 40px;
  }

  h2 {
    color: $theme-color-4;
    font-size: $font-size-xxxl;
    text-align: center;
  }

  .nft-carousel {
    text-align: center;
    margin: 0 40px;

    .slick-track {
      margin-bottom: 40px;

      .slick-slide div {
        margin: 0 5px;
      }
    }
  }

  .animated {
    position: relative;
  }

  .carousel-prev, .carousel-next {
    cursor: pointer;
    color: $color-white-1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-20px);
    font-size: 24px;
  }

  .carousel-next {
    right: 10px;
  }

  .carousel-prev {
    left: 10px;
  }

  .nft-card-item {
    .ant-image:hover {
      border-color: $theme-color-5;
    }
  }

  .nft-loading {
    width: 100%;
    height: 400px;
    max-height: 70vh;
    position: relative;

    .ant-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
    }
  }
}

.nft-container-empty {
  .center-inner-container {
    padding: 30px 20px;
  }

  text-align: center;

  h2 {
    color: $theme-color-4;
  }

  h3, h4 {
    color: $color-white-1;
  }

  h4 {
    margin-bottom: 1.5em;
  }

  a, a h4 {
    font-family: $font-3;
    color: $theme-color-4;
    font-size: 24px;
  }
}