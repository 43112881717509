@import "../../variables";

.center-container {
  margin: auto;
  padding: 20px;
  max-width: 100%;
  @media screen and (orientation: landscape) {
    max-width: 100vw;
  }

  .center-inner-container {
    max-width: 90vw;
    @media screen and (orientation: landscape) {
      max-width: 100vh;
    }
    border-radius: $container-border-radius;
    background: $container-background;
  }

  &.scroll .center-inner-container {
    overflow-y: auto;
  }
}