@import '../../variables';

span.ant-typography {
  &.font-size-lg {
    font-size: $font-size-lg;
  }

  &.font-size-md {
    font-size: $font-size-md;
  }

  &.font-size-sm {
    font-size: $font-size-sm;
  }
}