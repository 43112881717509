@import "OmsText";
@import "OmsInput";

.center.center.center {
  text-align: center;
}

.left.left.left {
  text-align: left;
}

.right.right.right {
  text-align: right;
}

.text-color-1.text-color-1.text-color-1 {
  color: $theme-color-1;
}

.text-color-2.text-color-2.text-color-2 {
  color: $theme-color-2;
}

.text-color-3.text-color-3.text-color-3 {
  color: $theme-color-3;
}

.text-color-4.text-color-4.text-color-4 {
  color: $theme-color-4;
}

.text-color-5.text-color-5.text-color-5 {
  color: $theme-color-5;
}

.text-color-6.text-color-6.text-color-6 {
  color: $theme-color-6;
}

.text-color-white.text-color-white.text-color-white {
  color: $color-white-1;
}