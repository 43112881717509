@import "../../variables";

.oms-text {
  font-size: $font-size-md;

  &.full-width.full-width.full-width {
    width: 100%;
  }

  &.auto-width.auto-width.auto-width {
    width: auto;
  }

  &.label {
    color: $theme-color-5;
    text-align: right;
    font-weight: bold;
    @media screen and (max-width: $md){
      text-align: left;
    }

    &:after {
      content: ':';
    }
  }

  &.text-value {
    text-align: left;
    color: $color-white-1;
  }
}