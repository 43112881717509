@import "variables";
.nft-character  {
  font-family: Cinzel, sans-serif;

  h3.ant-typography {
    font-size: $font-size-md;
    color: $theme-color-1;
    text-align: center;
  }

  @media screen and (orientation: landscape) {
    .ant-image-img {
      max-height: 80vh;
      width: auto;
      max-width: 100%;
    }
  }

  .ant-image {
    border-radius: 20px;
    border: solid 4px $theme-color-2;
    overflow: hidden;
  }

  .ant-card {
    .ant-card-head-title {
      text-align: center;
      font-weight: bold;
      font-size: $font-size-md;
      color: $theme-color-2;
    }

    .ant-card-grid {
      padding: 2px 1px;
      border-radius: 10px;
      overflow: hidden;

      > div {
        border: 1px solid $color-blue-1;
        border-radius: 10px;
        overflow: hidden;
        cursor: default;
      }

      .card-grid-item-title {
        color: $theme-color-5;
      }

      .card-grid-item-value {
        font-family: $font-4;
        color: $theme-color-2;
      }
    }
  }
}