@import '../../variables';

.signup-thankyou {
  .center-inner-container {
    padding: 40px 20px;
    text-align: center;
    font-family: $font-4;
    .check-icon {
      margin: 20px;
      font-size: 70px;
      color: $theme-color-1;
    }
    h1.ant-typography.ant-typography {
      margin: 0;
      color: $color-blue-1;
      text-align: center;
      width: 100%;
      display: inline-block;
    }
    h3.ant-typography.ant-typography {
      color: $color-white-1;
    }
    .ant-btn{
      margin-top: 20px;
      color: $theme-color-4;
      font-weight: bold;
    }
  }
}
