@import "../../variables";

.terms-and-conditions {
  &.center-container.scroll .center-inner-container {
    padding: 40px 0;
    overflow: hidden;
  }

  font-family: $font-6;
  font-size: $font-size-md;
  color: white;

  h3 {
    font-weight: bold;
    color: white;
    margin-top: 20px;
  }
}