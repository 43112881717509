@import "../../variables";

.account-flow-container {
  .login-form {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.forgot-password-modal {
  .ant-modal-content {
    background: rgba(0, 0, 0, 0.7);

    .full-width {
      button {
        width: 100%;
      }

    }

    h3 {
      color: $theme-color-4;
    }

    .ant-modal-body, .ant-form {
      background: none;
    }
  }
}