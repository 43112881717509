@import "../../variables";

.notify-form {
  text-align: center;

  h3 {
    color: $theme-color-4;
  }

  h4 {
    color: $theme-color-3;
  }
  .anticon{
    &.anticon-check-circle{
      color: $theme-color-5;
      font-size: 56px;
    }
  }
}