@import "variable";

.oms-form {
  background: rgba(0, 0, 0, 0.7);
}

.account-flow-container {
  margin: auto;
  padding: $padding-md;

  input, input.ant-input.ant-input.ant-input, .ant-input-password.ant-input-password.ant-input-password,
  input.ant-input-status-success.ant-input-status-success.ant-input-status-success, input:-webkit-autofill {
    &:hover {
      background: none;
    }

    background: none;
    font-size: $font-size-md;
    color: #D9D9D9;
  }

  .ant-form-item-explain.ant-form-item-explain {
    font-weight: bold;
  }

  .ant-input-suffix svg {
    color: #D9D9D9;
  }

  h1, h2, h3 {
    text-align: center;
    font-family: $font-4;
    color: $color-blue-1;
  }
  h4 {
    color: $color-white-1;
  }

  .full-width {
    button {
      width: 100%;
    }
  }

  .ant-form {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }

  .ant-form-item-control-input button {
    font-weight: bold;
  }

  .ant-divider-horizontal {
    color: $color-white-1;
    font-size: $font-size-xxl;
    margin-bottom: 0;
    border-top: solid 1px $color-white-1;

    &.ant-divider-with-text {
      margin-top: -$padding-lg;
      font-family: $font-4;
      font-weight: bold;
      border-top: none;
    }

    &::before, &::after {
      border-top: solid 1px $color-white-1;
    }
  }

  .ant-form-item {
    color: $color-white-1;
    font-size: large;
  }

  .login-social-btn {
    span {
      font-size: $font-size-base;
      font-weight: bold;
    }

    &.login-facebook.ant-btn {
      background: #3C538C;
      border-color: #3C538C;
    }

    &.login-google.ant-btn {
      background: #4281E5;
      border-color: #4281E5;
    }
  }
}

.ant-form {
  border: 0 solid red;
  border-radius: 10px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  max-width: 100%;
  display: table-cell;
  width: 500px;
}
.form-item input{
  &::placeholder {
    color: $theme-color-7;
  }
}

.form-item input, .ant-form-item .ant-input-affix-wrapper {
  background: none !important;
  color: $color-white-1;

  .ant-btn-link:disabled{
    color: $theme-color-3;
  }
}
