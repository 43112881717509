@import "../../variables";

.scroll-container {
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    color: rgba(255, 255, 255, 0.7);
  }
}