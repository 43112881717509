@import "variable";

.account-flow-container {
  .ant-form.register-form {
    font-family: $font-6;
    width: 800px;

    .ant-row {
      margin-bottom: $padding-md;
    }

    .ant-form-item-label {
      text-align: left;
    }

    .ant-form-item-label > label {
      color: $color-white-1;
      font-size: $font-size-md;
    }
  }
}
