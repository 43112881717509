@import "../../variables";
$bg: rgba(0, 0, 0, 0.2);

.building-modal {
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
  border: none;
  display: flex;
  flex-direction: column;
  color: $color-white-1;

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      // font-family: Cinzel, sans-serif;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white-1;
      font-family: Cinzel, sans-serif;

      .body-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        max-width: 90vw;
        font-size: 16px;
        margin: 10px;

      }

      .ant-typography {
        color: $color-white-1;
      }
    }
  }
  
}
