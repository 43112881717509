$font-1: "Zen Kaku Gothic Antique";
$font-2: "Zen Kaku Gothic Antique";
$font-3: Zen Kaku Gothic Antique;
$font-4: "Zen Kaku Gothic Antique";
$font-5: Zen Kaku Gothic Antique;
$font-6: Zen Kaku Gothic Antique;

$font-size-sm: 12px;
$font-size-base: 16px;
$font-size-md: $font-size-sm + 4px;
$font-size-lg: $font-size-md + 6px;
$font-size-xl: $font-size-lg + 6px;
$font-size-xxl: $font-size-xl + 6px;
$font-size-xxxl: $font-size-xxl + 6px;

$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$padding-xss: 4px; // more small
$color-white-1: #d9d9d9;
$color-blue-1: #1e90ff;
$color-facebook: #1877F2;
$color-twitter: #1DA1F2;

$container-border-radius: 10px;
$card-border-radius: 20px;
$container-background: rgba(0, 0, 0, 0.8);

$theme-color-1: #F9961D;
$theme-color-2: #F9854B;
$theme-color-3: #B3AB68;
$theme-color-4: #076CB2;
$theme-color-5: #049DE3;
$theme-color-6: #F84342;
$theme-color-7: #777;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}