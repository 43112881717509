@import "../../variables";

.school-module-container {
  .button-container {
    .built {
      background-color: green;
    }
    .available {
      background-color: white;
    }
    .unavailable {
      background-color: grey;
    }
  }
}
.ant-notification-notice-message {
  color: black !important;
}
.ant-notification-notice-description {
  color: black !important;
}