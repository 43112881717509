.popup-comming-soon{
    overflow: hidden;
    max-width: 90vw;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.8);
    border: none;
    padding: 0;
    .ant-modal-content {
      background: none;
  
      .ant-modal-body {
        background: none;
        // font-family: Cinzel, sans-serif;
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-white-1;
  
        .body-wrapper {
          // font-family: Cinzel, sans-serif;
          overflow: hidden;
          max-width: 90vw;
          font-size: 22px;
          margin: 10px;
        }
        .footer-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 10px 0;
          .ant-btn {
            margin: 0 5px;
          }
        }
        .ant-form-item.ant-form-item {
          margin-bottom: 5px;
  
          label, span {
            font-weight: bolder;
          }
  
          label {
            font-size: $font-size-md;
            color: $color-blue-1;
            text-align: right;
            min-width: 120px;
          }
  
          span {
            color: $color-white-1;
          }
        }
        .ant-typography {
          color: $color-white-1;
        }
      }
    }
  }
  