@import "../variables";

.mint-container {
  .center-inner-container {
    padding: 30px 10px;
    position: relative;
    min-height: 600px;

    h2 {
      // font-family: Cinzel, sans-serif;
      color: $theme-color-4;
      text-align: center;
      font-size: $font-size-xxxl;
    }
    h3 {
      // font-family: Cinzel, sans-serif;
      color: $theme-color-4;
      text-align: center;
      font-size: $font-size-xxl;
    }

    .supply-number {
      text-align: center;
      font-size: 50px;
      line-height: 1.6;
      color: $theme-color-5;
      // font-family: 'Cinzel';
    }
    .sale-info {
      display: inline-block;
      width: fit-content;
      padding: 20px;
      border: 2px;
      border-style: solid;
      border-radius: 10px;
      border-color: $color-white-1;
      margin: 10px 10px;
    }

    button {
      // font-family: Cinzel, sans-serif;
    }

    p {
      color: $theme-color-3;
      font-size: 14px;
      text-align: center;
      margin: 10px;
    }
    .grey {
      color: $theme-color-7;
      font-size: 14px;
      text-align: center;
    }
    .highlight {
      color: $theme-color-1;
      font-size: 14px;
      text-align: center;
      padding: 5px;
      border: 1px;
      border-style: solid;
      border-radius: 10px;
      border-color: $color-white-1;
    }
  a {
    color: $theme-color-2;
    font-size: 14px;
    text-align: center;
  }
  }
}
.mint-package {
  background-color: $color-white-1;
  display: flex!important;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  border: 1px solid $theme-color-4;
  border-radius: 5px;
  flex-grow: 1;
  cursor: pointer;
  .mint-package-title {
    // font-family: Cinzel, sans-serif;
    color: $theme-color-4;
    text-align: center;
    font-size: $font-size-md;
    padding: 5px;
  }
  .mint-package-description {
    // font-family: Cinzel, sans-serif;
    text-align: center;
    font-size: $font-size-sm;
    padding: 5px;
    flex-grow: 1;
  }
  .mint-package-price-detail {
    // font-family: Cinzel, sans-serif;
    text-align: right;
    font-size: $font-size-sm;
    padding: 0px 5px 0 5px;
  }
  .mint-package-price {
    background-color: $theme-color-4;
    font-weight: bold;
    color: $color-white-1;
    padding: 5px;
    width: 100%;
  }
  
  .mint-package-price-org {
    text-decoration-line: line-through;
    font-weight: bold;
    padding: 5px;
  }
}

.mint-package-selected {
  border: 4px solid $theme-color-2;
}


.mint-address-form {
    font-family: $font-6;
    width: 100%!important;
    .ant-row {
      margin-bottom: $padding-md;
    }

    .ant-form-item-label {
      text-align: left;
    }

    .ant-form-item-label > label {
      color: $color-white-1;
      font-size: $font-size-md;
    }
}
