@import "../../variables";

.oms-input {
  &.full-width.full-width.full-width {
    width: 100%;
  }

  &.auto-width.auto-width.auto-width {
    width: auto;
  }

  &, > input {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
  }
}