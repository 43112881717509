.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal>.ant-menu-item-selected a,
.ant-menu-horizontal>.ant-menu-item a:hover {
    color: #21bf96 !important;
}

.ant-modal-content {
    overflow: auto;
    border-radius: 1rem;
}

.shadow-dark-1000 {
    --tw-shadow-color: #0D0415;
    --tw-shadow: var(--tw-shadow-colored);
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bg-dark-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(32 34 49 / var(--tw-bg-opacity));
}

.bg-dark-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 21 34 / var(--tw-bg-opacity));
}

.ant-card-body:hover {
    --tw-border-opacity: 1;
    border-color: rgb(65 74 108 / var(--tw-border-opacity));
}

.ant-card:hover {
    --tw-border-opacity: 1;
    border-color: rgb(65 74 108 / var(--tw-border-opacity));
}

.text-high-emphesis {
    --tw-text-opacity: 1;
    color: rgb(227 227 227 / var(--tw-text-opacity));
}

.border-dark-700 {
    --tw-border-opacity: 1;
    border-color: rgb(46 51 72 / var(--tw-border-opacity));
}

.rounded-\[24px\] {
    border-radius: 24px;
}

.bg-dark-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 21 34 / var(--tw-bg-opacity));
}

.border-dark-700 {
    --tw-border-opacity: 1;
    border-color: rgb(46 51 72 / var(--tw-border-opacity));
}

.border {
    border-width: 1px;
}

.button-primary {
    border-radius: 0.625rem;
}

.button-primary span {
    color: white;
    font-weight: 600;
}

button[disabled] {
    opacity: 0.4;
}

.text-higher-emphesis {
    --tw-text-opacity: 1;
    color: rgb(252 252 253 / var(--tw-text-opacity));
}

.from-blue {
    --tw-gradient-from: #0993EC;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(9 147 236 / 0));
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.to-pink-600 {
    --tw-gradient-to: #E50EAE;
}

.w-full {
    width: 100%;
}

.height-52px {
    height: 52px;
}

.gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
}

.margin-top-1-5 {
    margin-top: .375rem;
}

.card-radius {
    border-radius: 1rem;
}

.inputswap-div {
    display: flex;
    flex-flow: row nowrap;
}

.inputswap {
    font-size: 25px;
    font-weight: 900;
    background-color: transparent;
    border: none;
    color: white;
    width: 80%;
}

.inputswap[disabled] {
    background-color: transparent;
    color: white;
}

.swapchange-button-div {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.swapchange-button {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.6rem;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 17px;
    gap: 7px;
    border: none;
    color: rgb(132, 132, 132);
    background-color: transparent;
}

.swapchange-button:hover {
    color: white;
    background-color: transparent !important;
}

.swap-card {
    width: 430px;
    box-shadow: 0 0.5rem 1.2rem rgb(189 197 209 / 20%);
    border: 1px solid #0D0415;
    border-radius: 1rem;
    font-size: 16px;
    font-weight: 500;
}

.swap-input {
    padding: 0;
    font-weight: 500;
    font-size: 23px;
    display: block;
    width: 100%;
    color: #848484;
}

.swap-button {
    background-Color: #afafaf;
    border: 1px solid white;
    border-radius: 12px;
    font-weight: 600;
}

.swap-maxbutton {
    background-color: transparent;
    color: white;
    border: none;
    float: right;
}

.swap-maxbutton:hover {
    background-color: #848484;
}

.loadingCenter>div {
    max-width: 100px;
}

.modalLoading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalLoading .ant-modal-content {
    background-color: transparent;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.loadingCenter>img {
    width: 100%;
}

.topBarSpace {
    justify-content: right;
}

.topBarLogo>img {
    width: 80%;
}

.homeToolTip:hover {
    font-weight: 900;
}

.homeToolTip>span:hover {
    font-size: 18px;
}

@media (max-width: 767px) {
    .topBarLogo {
        text-align: center;
    }
    .topBarWallet>div {
        justify-content: center;
    }
    .topBarSpace {
        justify-content: center;
    }
}

@media (max-width: 992px) {
    .topBarLogo {
        text-align: center;
    }
    .topBarLogo>img {
        width: 50%;
    }
}

.menu-button {
        background-color: #000000!important;
        border: 1px solid white;
        border-radius: 12px;
        font-weight: 600;
      
}
.menu-button:hover {
    background-color: #555555!important;
    border-color: white;
}
.menu-active .menu-button {
    background-color: #b6b6b6!important;
    border: 1px solid white;
    
    border-radius: 12px;
    font-weight: 600;
  
}

.fullwidth-button{
    width: 100%;
    background-color: #25d0ee;
    border: 1px solid white;
    border-radius: 12px;
    font-weight: 600;
}
.fullwidth-button:hover{
    background-color: #cfcfcf!important;
    border-color: white;
}

.NFTImage:hover {
    border: 4px solid #1981e1!important;
}
.facebook-button-class {
    font-family: "Google Sans",arial,sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: 1px solid rgb(95, 141, 227);
    height:40px;
    width:178px;
    border-radius:5px;
}