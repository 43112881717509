@import '../../variables';
@import "ChangeUserName";

.account-info-container {
  color: $color-white-1;
  overflow: hidden;

  .center-inner-container {
    padding: 20px;
  }

  h3.ant-typography {
    font-family: $font-4;
    color: $color-blue-1;
  }

  .header {
    position: relative;
    height: 70px;

    .logout-btn {
      position: absolute;
    }

    h1 {
      position: absolute;
      line-height: 90px;
    }

    h1.ant-typography.ant-typography {
      margin: 0;
      font-family: $font-4;
      color: $color-blue-1;
      text-align: center;
      width: 100%;
      display: inline-block;
    }

    .logout-btn {
      top: 28px;
      right: 30px;
    }
  }

  .ant-form-item.ant-form-item {
    margin-bottom: 5px;

    label, span {
      font-weight: bolder;
    }

    label {
      font-size: $font-size-md;
      color: $color-blue-1;
    }

    span {
      color: $color-white-1;
    }
  }

  .text-center {
    text-align: center;
    margin: 10px auto;

    > div {
      display: contents;
    }
  }

  .referral-code {
    width: 300px;
    max-width: 100%;
  }

  .referral-link {
    width: 500px;
    max-width: 100%;
  }

  .ant-divider {
    border-bottom: solid 1px $color-white-1;
  }

  .referral-code, .referral-link {
    .ant-form-item-control-input-content > .ant-typography {
      @media screen and (min-width: $md) {
        position: absolute;
        line-height: 40px;
        margin-left: 10px;
      }
      color: $theme-color-4;
    }
  }

  .logout-btn .ant-form-item-control-input-content {
    margin: 20px auto;
    text-align: center;

    button {
      width: 200px;
      background: $theme-color-3;

      span {
        color: black;
      }
    }
  }
 .ant-tabs-nav-list{
   margin: auto;
 }
  .ant-tabs-tab.ant-tabs-tab {
    font-weight: bold;
    font-size: $font-size-lg;

    .ant-tabs-tab-btn {
      color: $theme-color-3;
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $theme-color-5;
      }
    }
  }

  .social-share {
    text-align: right;
    font-size: 32px;
    color: $color-white-1;

    > button {
      margin-left: 5px;
    }

    .anticon-facebook {
      color: $color-facebook;
    }

    .anticon-twitter {
      color: $color-twitter;
    }
  }

}
