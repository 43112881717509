@import "../../variables";

.noClick {
  pointer-events: none;
}

.home-phaser {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.inactive {
    visibility: hidden;
  }
}

.coming-soon-modal {
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  border: none;

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      // font-family: Cinzel, sans-serif;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white-1;

      .body-wrapper {
        // font-family: Cinzel, sans-serif;
        overflow: hidden;
        max-width: 90vw;
        font-size: 22px;
        margin: 10px;
      }

      .ant-typography {
        color: $color-white-1;
      }
    }
  }
}

.mining-modal {
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  border: none;

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      // font-family: Cinzel, sans-serif;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white-1;

      .body-wrapper {
        // font-family: Cinzel, sans-serif;
        overflow: hidden;
        max-width: 90vw;
        font-size: 22px;
        margin: 10px;
      }

      .ant-typography {
        color: $color-white-1;
      }
    }
  }
}

.welcome-wrapper {
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);

}

.welcome-inner-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-family: Cinzel, sans-serif;

  div {
    color: $theme-color-4;
    font-size: $font-size-xl;
  }

  p {
    a {
      color: $theme-color-3;
    }

    color: $theme-color-4;
    font-size: $font-size-md;
  }

  h2.ant-typography {
    @media screen and (max-width: $md) {
      font-size: 3em;
    }
    @media screen and (max-width: $sm) {
      font-size: 2em;
    }
    font-size: 4em;
    // font-family: Cinzel, sans-serif;
    color: $color-white-1;
    text-align: center;
  }
}

.popup-link-missing-wallet{
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  padding: 0;
  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      // font-family: Cinzel, sans-serif;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white-1;

      .body-wrapper {
        // font-family: Cinzel, sans-serif;
        overflow: hidden;
        max-width: 90vw;
        font-size: 22px;
        margin: 10px;
      }
      .footer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        .ant-btn {
          margin: 0 5px;
        }
      }
      .ant-form-item.ant-form-item {
        margin-bottom: 5px;

        label, span {
          font-weight: bolder;
        }

        label {
          font-size: $font-size-md;
          color: $color-blue-1;
          text-align: right;
          min-width: 120px;
        }

        span {
          color: $color-white-1;
        }
      }
      .ant-typography {
        color: $color-white-1;
      }
    }
  }
}

