@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes faceOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.face-in {
  animation: faceIn .5s;
}

.face-out {
  animation: faceOut .5s;
}

