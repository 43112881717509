@import "../../variables";

.center-container.nft-container {
  margin-top: 150px;
}

.nft-list-view {
  width: 100%;
  max-width: 90vw;
  border-radius: $card-border-radius;

  .nft-cards {
    background: none;

    .ant-card-grid {
      width: 25%;
      @media screen and (max-width: $xl) {
        width: 33%;
      }
      @media screen and (max-width: $md) {
        width: 50%;
      }
      @media screen and (max-width: $sm) {
        width: 100%;
      }
      text-align: center;
      box-shadow: none;

      .ant-image {
        border-radius: $card-border-radius;
        border: solid 4px $theme-color-2;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;

        &:hover {
          border: solid 4px $theme-color-4;
        }
      }
    }

  }


  h4 {
    // font-family: Cinzel, sans-serif;
    font-size: $font-size-base;
    color: $theme-color-1;
  }

  .nft-card-item {
    display: inline-grid;
    position: relative;
  }
  .nft-card-item-buttons {
    display: flex;
    flex-direction: row;
  }
  .nft-status-icon {
    position: absolute;
    top: 30px;
    left: 30px;
    color:red;
  }

  &.nft-list-view.nft-list-view button {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin: 0;

    &:hover {
      font-size: 18px;
    }
  }


  button {
    width: 200px;
    max-width: 100%;
    margin: 0 10px;
    color: rgb(91, 131, 206);
    font-size: 18px;
    line-height: 24px;

    &:hover {
      font-size: 22px;
      line-height: 24px;
      font-weight: bold;
    }

    span.anticon {
      position: absolute;
      transform: translateX(-100%);
      font-size: 16px;
      line-height: 24px;

      &:hover {
        font-size: 18px;
      }
    }
  }

  margin-bottom: 40px;
}
