@import "../../variables";
@import "claimshardDisable";

.burger-menu {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;

  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: $color-white-1;
    margin: 6px 0;
    transition: 0.4s;
  }


  &.open {
    .bar1 {
      transform: translate(0, 11px) rotate(-45deg);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      transform: translate(0, -11px) rotate(45deg);
    }
  }
}

.popup-comming-soon {
  color: white;
  text-align: center;
  
}

.mobile-menu {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  &.open {
    z-index: 1;

  }
  &.close {
    z-index: -1;
  }
  h2.ant-typography {
    font-size: $font-size-xl;
    font-family: $font-5;
    color: $theme-color-2;
    margin-bottom: 30px;
  }

  .ant-divider {
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.7);
    margin: 1px 0;
  }

  .container-outer {
    width: 100%;
    height: 100%;
    display: flex;
    vertical-align: middle;
    overflow-y: auto;
    .container-inner {
      margin: auto;
      width: 100%;
      text-align: center;
      display: block;

      .logo-menu {
        display: inline-block;
        margin: 20px auto;
        width: 250px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          &:hover {
            a {
              color: $theme-color-5;
            }
          }
          a.menu-active {
            color: $theme-color-5;
            cursor: not-allowed;
          }
          a.menu-coming-soon {
            color: $theme-color-1;
            cursor: not-allowed;
          }

          a {
            font-family: $font-4;
            font-weight: bold;
            font-size: $font-size-xl;
            line-height: 80px;
            color: $theme-color-3;
            i {
              font-size: $font-size-md;
              line-height: $font-size-md;
              display: block;
              margin-top: -20px;
            }
          }
        }
      }
    }
  }

  .bugger-button {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: $color-white-1;
    line-height: 120px;
  }
}
.menu-highlight {
  color: $theme-color-1!important;
}

